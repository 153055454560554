<section id="layout">
  <header id="Header" class="border-bottom animation">
    <div class="container ml-5">
      <img class="logo" src="/assets/logo/simple-logo.svg" alt="Logo" />
    </div>
  </header>

  <div id="body" class="row g-3 mb-5 relative">
    <div
      id="img"
      class="col-md-6 col-lg-6 col-xl-6 px-0"
    >
      <img
        src="/assets/img/signupImage.webp"
        alt="Simple Pos Banner"
        class="img-fluid w-100"
      />
    </div>
    <div id="Form" class="col-md-6 col-lg-6 col-xl-6 px-0">
      <router-outlet />
    </div>
  </div>

  <footer id="Footer" class="p-2 animation-toTop">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <span class="footer-font">Simple Tech Ltd</span>
        </div>
      </div>
    </div>
  </footer>
</section>
